<template>
	<base-section>
		<v-container v-if="this.$i18n.locale === 'en'">
			<base-section-heading title="RCE Zhangjiakou">
				United Nations University Zhangjiakou Regional Centre of
				Expertise on ESD
			</base-section-heading>

			<v-row class="rce-body-text">
				<v-col sm="12" md="10" lg="8" xl="6" class="mx-auto">
					<h1>Regional Centre of Expertise on ESD</h1>
					<p>
						RCE (Regional Centre of Expertise) is a global network
						advocated by the United Nations University, aimed at
						promoting sustainable development through education and
						collaboration. The mission of RCE is to promote
						collaboration among communities, academic institutions,
						businesses, and governments around the world to
						integrate the United Nations Sustainable Development
						Goals (SDGs) into education and practice in various
						regions. The vision of the RCE network is to promote
						regional sustainable development through education and
						create a sustainable future, with the following goals:
					</p>
					<ul>
						<li>
							Educational Integration - Integrating Sustainable
							Development Education (ESD) into Projects and
							Disciplines
						</li>
						<li>
							Regional Development - Enhancing the Sustainability
							of Communities and Regions through High Quality
							Education
						</li>
						<li>
							Smart Platform - Creating Collaboration and
							Knowledge Sharing Spaces
						</li>
						<li>
							Public awareness - enhancing public awareness of the
							United Nations Sustainable Development Goals and
							supporting the long-term development of quality of
							life
						</li>
					</ul>
					<p>
						RCE centers have over 190 locations worldwide,
						distributed in multiple countries and regions,
						connecting resources and expertise from different
						regions and becoming a collaborative platform. To
						achieve the United Nations Sustainable Development Goals
						(SDGs) at the local and regional levels.
					</p>
					<v-img
						:src="
							require('@/assets/images/rce/global_rce_network.png')
						"
					/>
					<p class="text-caption">
						RCE official website:
						<a
							href="https://unu.edu/ias/series/global-rce-network"
							target="_blank"
							>https://unu.edu/ias/series/global-rce-network
							<v-icon size="small" color="primary"
								>mdi-open-in-new</v-icon
							>
						</a>
					</p>

					<h1>RCE Zhangjiakou</h1>
					<p>
						With strong support from the Zhangjiakou Energy Bureau,
						local and national enterprises, universities, and public
						welfare organizations, and relying on the third SD China
						Competition Park, the RCE Zhangjiakou Center officially
						submitted its application and was approved for
						establishment in 2024.
					</p>

					<h2>Mission and Vision of RCE Zhangjiakou</h2>
					<ul>
						<li>
							Supporting Global Goals: Responding to the United
							Nations Sustainable Development Goals and National
							Green Ecological Development Strategies
						</li>
						<li>
							Promoting High Quality Development: Assisting
							Zhangjiakou City in achieving high-quality
							development and enhancing its international
							influence
						</li>
						<li>
							Building an Innovation Platform: Integrating
							Resources, Establishing an Open Co construction and
							Interactive Communication Platform, and Promoting
							Innovation Opportunities
						</li>
						<li>
							Promoting Green Living: Guiding Green Lifestyle and
							Contributing to the Sustainable Development of
							Zhangjiakou City
						</li>
						<li>
							Conveying the concept of sustainability: as an RCE
							city, implementing high-quality projects and
							spreading the concept of sustainable development to
							both domestic and international markets
						</li>
					</ul>

					<p>
						RCE Zhangjiakou is authorized by the SD China Organizing
						Committee to be executed by Guangyao I Neng (Beijing)
						Technology Development Co., Ltd. Relevant participating
						units and other organizations involved in related fields
						are now invited to join, guided by the themes of
						high-quality education and clean energy in the 17 United
						Nations Sustainable Development Goals, actively carrying
						out industry university research cooperation, human
						resources training, and educational science
						popularization activities. By open sharing and
						interactive communication, we will contribute to the
						construction of the Zhangjiakou Renewable Energy
						Demonstration Zone and the sustainable development
						process, create a benchmark for sustainable development
						education in northern Hebei, and tell the positive story
						of sustainable development in Zhangjiakou on the
						international stage.
					</p>

					<h2>RCE Zhangjiakou Center applies for supporting units</h2>
					<v-row>
						<v-col cols="6">
							<ul>
								<li>Zhangjiakou Energy Bureau</li>
								<li>
									Zhangjiakou Zhonghe New Energy Group Co.,
									Ltd
								</li>
								<li>
									Zhangjiakou Jiaotou Sports Culture Tourism
									Development Co., Ltd
								</li>
								<li>
									Zhangjiakou Charity Volunteer Federation
								</li>
								<li>
									Zhangjiakou Anxing Biotechnology Co., Ltd
								</li>
							</ul>
						</v-col>
						<v-col cols="6">
							<ul>
								<li>
									Suzhou Industrial Park Green Light-Year
									Science Popularization Service Center
								</li>
								<li>
									China University of Mining and Technology
								</li>
								<li>
									Hebei University of Architecture and
									Engineering
								</li>
								<li>Xi'an Jiaotong Liverpool University</li>
								<li>Xi'an Jiaotong University</li>
								<li>Guangdong Midea HVAC Equipment Co., Ltd</li>
							</ul>
						</v-col>
					</v-row>

					<h1>Contact us</h1>
					<p>Tel: 010-67080760</p>
					<p>Email: rce_zjk@163.com</p>
				</v-col>
			</v-row>
		</v-container>

		<v-container v-if="this.$i18n.locale === 'cn'">
			<base-section-heading title="RCE张家口">
				联合国大学区域可持续发展教育张家口中心
			</base-section-heading>

			<v-row class="rce-body-text">
				<v-col sm="12" md="10" lg="8" xl="6" class="mx-auto">
					<h1>RCE可持续发展教育区域中心</h1>
					<p>
						RCE（Regional Centre of
						Expertise，可持续发展教育区域中心）是由联合国大学倡导的全球性网络，旨在通过教育与协作推动可持续发展。RCE的使命是促进全球各地社区、学术机构、企业和政府合作，将联合国可持续发展目标（SDGs）融入各地区的教育和实践。RCE网络的愿景是通过教育推动区域可持续发展，打造可持续的未来，目标如下：
					</p>
					<ul>
						<li>
							教育融合——在项目和学科中融入可持续发展教育（ESD）
						</li>
						<li>
							区域发展——通过高质量教育提升社区和区域的可持续性
						</li>
						<li>智慧平台——创建协作与知识共享空间</li>
						<li>
							公众意识——提升公众联合国可持续发展目标认知，助力生活质量的长远发展
						</li>
					</ul>
					<p>
						RCE中心在全球已有超过190个，分布在多个国家和地区，连接各地的资源与专长，成为一个协作平台。在地方和区域层面上实现联合国的可持续发展目标（SDGs）。
					</p>
					<v-img
						:src="
							require('@/assets/images/rce/global_rce_network.png')
						"
					/>
					<p class="text-caption">
						RCE官方网址：<a
							href="https://unu.edu/ias/series/global-rce-network"
							target="_blank"
							>https://unu.edu/ias/series/global-rce-network
							<v-icon size="small" color="primary"
								>mdi-open-in-new</v-icon
							>
						</a>
					</p>

					<h1>RCE张家口</h1>
					<p>
						在张家口市能源局，当地及全国各企业、高校及公益组织的大力支持下，依托第三届
						SD 中国竞赛园区，RCE
						张家口中心于2024年正式提交申请并获准成立。
					</p>

					<h2>RCE张家口的使命与愿景</h2>
					<ul>
						<li>
							支持全球目标：响应联合国可持续发展目标与国家绿色生态发展战略
						</li>
						<li>
							推动高质量发展：助力张家口市高质量发展，提升其国际影响力
						</li>
						<li>
							构建创新平台：整合资源，搭建开放共建与互动交流平台，推动创新机会
						</li>
						<li>
							推广绿色生活：引导绿色生活方式，为张家口市可持续发展贡献力量
						</li>
						<li>
							传递可持续理念：作为RCE城市，实施优质项目，将可持续发展理念传播至国内外
						</li>
					</ul>

					<p>
						RCE 张家口由 SD
						中国组委会授权光耀我能（北京）科技发展有限公司负责具体执行，邀请相关参赛单位及其他涉及相关领域的组织单位共同加入，以
						17
						个联合国可续发展目标中优质教育、清洁能源等相关主题为导向，积极开展产学研合作、人力资源培训和教育科普活动。通过开放共享和互动交流，为张家口市可再生能源示范区建设和可持续发展进
						程贡献力量，打造冀北地区可持续发展教育标杆，在国际舞台讲述张家口可持续发展正能量故事。
					</p>

					<h2>RCE张家口中心申请支持单位</h2>
					<v-row>
						<v-col cols="6">
							<ul>
								<li>张家口市能源局</li>
								<li>张家口中合新能源集团有限公司</li>
								<li>张家口市交投体育文化旅游发展有限公司</li>
								<li>张家口安兴生物科技有限公司</li>
								<li>张家口慈善义工联合会</li>
							</ul>
						</v-col>
						<v-col cols="6">
							<ul>
								<li>苏州工业园区绿色光年科普服务中心</li>
								<li>西安交通大学</li>
								<li>河北建筑工程学院</li>
								<li>西交利物浦大学</li>
								<li>中国矿业大学</li>
								<li>广东美的暖通设备有限公司</li>
							</ul>
						</v-col>
					</v-row>

					<h1>联系我们</h1>
					<p>电话：010-67080760</p>
					<p>邮箱：rce_zjk@163.com</p>
				</v-col>
			</v-row>
		</v-container>
	</base-section>
</template>

<script>
export default {
	data: () => ({
		socials: [
			{
				name: "wechat",
				icon: "wechat",
			},
			{
				name: "weibo",
				icon: "sina-weibo",
			},
			{
				name: "tiktok",
				icon: "music-note-outline",
			},
		],
	}),
};
</script>

<style lang="scss" scoped>
.rce-body-text {
	h1 {
		margin-bottom: 1rem;
	}
	h2 {
		margin-bottom: 1rem;
	}
	ul {
		margin-bottom: 1rem;
	}
}
</style>